import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import { NotificationsProvider } from "./context/NotificationsContext.jsx";
// import { initializeSentry } from "../monitoring/sentry.jsx";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// Initialize Sentry
// initializeSentry();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <NotificationsProvider>
      <App />
    </NotificationsProvider>
  </Provider>
);

import axios from "axios";
import { refreshAccessToken } from "./authentication.jsx";
import { jwtDecode } from "jwt-decode";

// Function to get the subdomain
export const getSubdomain = () => {
  const hostname = window.location.hostname;

  // In development environment on localhost, the subdomain could be handled differently
  if (hostname === "localhost" || hostname === "127.0.0.1") {
    // You could decide to get the subdomain from localStorage
    return JSON.parse(localStorage.getItem("subdomain")) || "default";
  }

  // In production, the subdomain is obtained from the first part of the hostname
  return hostname.split(".")[0];
};

// Use environment variable if available, fallback to default if not
// export const VITE_API_COMPLETE_URL = import.meta.env.VITE_API_COMPLETE_URL;
export const VITE_API_COMPLETE_URL =
  import.meta.env.VITE_API_COMPLETE_URL &&
  import.meta.env.VITE_API_COMPLETE_URL !== "http://localhost:3000/"
    ? import.meta.env.VITE_API_COMPLETE_URL
    : "http://localhost:8080/api/V0.0.0/";

// Axios instance only for public APIs that do not require authorization
export const loginAxiosInstance = axios.create({
  baseURL: VITE_API_COMPLETE_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Base axios configuration
export const axiosInstance = axios.create({
  baseURL: VITE_API_COMPLETE_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Axios instance only for public APIs that do not require authorization
export const axiosInstancePublic = axios.create({
  baseURL: VITE_API_COMPLETE_URL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Default configuration for headers
export const config = {
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
};

// Request Interceptor to attach the access token if available
axiosInstance.interceptors.request.use(
  (config) => {
    const subdomain = getSubdomain();

    if (config.baseURL.includes("localhost")) {
      config.baseURL = `http://${subdomain}.${
        import.meta.env.VITE_API_BASE_URL
      }`;
    } else {
      config.baseURL = `https://${subdomain}.${config.baseURL.replace(
        "https://",
        ""
      )}`;
    }

    const accessToken = JSON.parse(localStorage.getItem("access"));
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor to handle token refresh logic
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is okay, return it directly
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the error is due to an expired token
    if (
      (error.response.status === 401 || error.response.status === 500) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // Get the refresh token from local storage
      const refreshToken = JSON.parse(localStorage.getItem("refresh"));

      const refreshTokenDecoded = jwtDecode(refreshToken);

      // Check if the refresh token is available
      // Check if the refresh token is expired.
      // RefreshTokenDecoded is the json object of the decoded token. I need to decode it to find the expiry time.
      // RefreshTokenDecoded.exp is the expiry time of the token. The Date function needs the date in milliseconds,
      // meanwhile the .exp is in seconds, so I need to multiply it by 1000 to get the correct date.
      // If the refresh token is not available or expired, redirect to login page and clean the local storage.
      if (
        !refreshToken ||
        new Date(refreshTokenDecoded.exp * 1000) < new Date()
      ) {
        localStorage.clear();
        localStorage.setItem("sessionExpired", "true");
        window.location.href = "/login"; // Redirect to login page with session expired message
        return Promise.reject(error);
      } else {
        try {
          // Attempt to get a new access token using the refresh token
          const response = await refreshAccessToken(refreshToken);
          const newAccessToken = response.data.access;
          const newRefreshToken = response.data.refresh;

          localStorage.setItem("access", JSON.stringify(newAccessToken));
          localStorage.setItem("refresh", JSON.stringify(newRefreshToken));

          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axios(originalRequest); //recall Api with new token
        } catch (error) {
          localStorage.clear();
          localStorage.setItem("sessionExpired", "true");
          window.location.href = "/login"; // Redirect to login page with session expired message
          return Promise.reject(error);
        }
      }
    } else if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/403";
      return Promise.reject(error);
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export const buildQueryParams = (params) => {
  return Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
};

export const addQueryParams = (endpoint = "", queryParams = {}) => {
  const queryString = buildQueryParams(queryParams);

  if (queryString) {
    // Automatically detect if the endpoint already has query parameters
    if (endpoint.includes("?")) {
      endpoint = `${endpoint}&${queryString}`;
    } else {
      endpoint = `${endpoint}?${queryString}`;
    }
  }

  return endpoint;
};

import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    openSidebar: true,
    openComments: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.openSidebar = !state.openSidebar;
    },
    setSidebarOpen: (state, action) => {
      state.openSidebar = action.payload;
    },
    openSidebarAction: (state) => {
      state.openSidebar = true;
    },
    closeSidebarAction: (state) => {
      state.openSidebar = false;
    },
    toggleComments: (state) => {
      state.openComments = !state.openComments;
    },
    setCommentsOpen: (state, action) => {
      state.openComments = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  setSidebarOpen,
  openSidebarAction,
  closeSidebarAction,
  toggleComments,
  setCommentsOpen,
} = uiSlice.actions;

export default uiSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    attachments: {
      pagination: { next: null, previous: null, count: 0 },
      data: [],
    },
  },
  reducers: {
    setAttachmentResponse: (state, action) => {
      state.attachments = action.payload;
    },
    clearAttachments: (state) => {
      state.attachments = [];
    },
    addAttachment: (state, action) => {
      state.attachments.push(action.payload);
    },
    removeAttachment: (state, action) => {
      state.attachments = state.attachments.filter(
        (attachment) => attachment.id !== action.payload
      );
    },
    addNewPage(state, action) {
      state.attachments.data = state.attachments.data.concat(
        action.payload.data
      );
      state.attachments.pagination = action.payload.pagination;
    },
    editDescriptionAttachmentById(state, action) {
      state.attachments.data = state.attachments.data.map((attachment) => {
        if (attachment.id === action.payload.id) {
          attachment.source.description = action.payload.description;
        }
        return attachment;
      });
    },
  },
});

export const {
  setAttachmentResponse,
  clearAttachments,
  addAttachment,
  removeAttachment,
  addNewPage,
  editDescriptionAttachmentById,
} = gallerySlice.actions;

export default gallerySlice.reducer;

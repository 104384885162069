import axios from "axios";
import {
  axiosInstance,
  axiosInstancePublic,
  VITE_API_COMPLETE_URL,
  loginAxiosInstance,
  getSubdomain,
} from "./common.jsx";

export const refreshAccessToken = async (refresh) => {
  const apiEndpoint = VITE_API_COMPLETE_URL + "auth/token/refresh/";

  return axios
    .post(apiEndpoint, { refresh })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const login = async (data) => {
  const apiEndpoint = "auth/token/";

  return loginAxiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const register = async (data) => {
  const apiEndpoint =
    VITE_API_COMPLETE_URL.replace("://", "://" + getSubdomain() + ".") +
    "auth/register/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = async (refresh) => {
  const apiEndpoint = "auth/logout/";

  return axiosInstance
    .post(apiEndpoint, { refresh })
    .then((response) => {
      localStorage.clear();
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const otpVerification = async (data) => {
  const apiEndpoint = "auth/verify-email-otp/";

  return axiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = async (data) => {
  const apiEndpoint = "auth/forgot-password/";

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = async (data) => {
  const apiEndpoint = "auth/change-password/";

  return axiosInstance
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = async (token, data) => {
  const apiEndpoint = `auth/reset-password/${token}/`;

  return axiosInstancePublic
    .post(apiEndpoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

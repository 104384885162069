import { createSlice } from "@reduxjs/toolkit";

export const archiveSlice = createSlice({
  name: "archive",
  initialState: {
    archive: [],
    services: {
      data: [],
      pagination: { next: null, previous: null, count: 0 },
    },
    tasks: { data: [], pagination: { next: null, previous: null, count: 0 } },
    subtasks: {
      data: [],
      pagination: { next: null, previous: null, count: 0 },
    },
    contentHandlers: [],
    selectedHandler: null,
    rows: [],
  },
  reducers: {
    setContentHandlers: (state, action) => {
      state.contentHandlers = action.payload;
    },
    setSelectedHandler: (state, action) => {
      state.selectedHandler = action.payload;
    },
    clearSelectedHandler: (state) => {
      state.selectedHandler = null;
    },
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setTasks: (state, action) => {
      const { data, pagination } = action.payload;
      state.tasks = { data, pagination };
    },
    setSubtasks: (state, action) => {
      const { data, pagination } = action.payload;
      state.subtasks = { data, pagination };
    },
    setServices: (state, action) => {
      const { data, pagination } = action.payload;
      state.services = { data, pagination };
    },
    removeServiceById: (state, action) => {
      const id = action.payload;
      const newData = state.services.data.filter((item) => item.id !== id);
      state.services.data = newData;
      state.services.pagination.count -= 1;
    },
    removeTaskById: (state, action) => {
      const id = action.payload;
      const newData = state.tasks.data.filter((item) => item.id !== id);
      state.tasks.data = newData;
      state.tasks.pagination.count -= 1;
    },
    removeSubtaskById: (state, action) => {
      const id = action.payload;
      const newData = state.subtasks.data.filter((item) => item.id !== id);
      state.subtasks.data = newData;
      state.subtasks.pagination.count -= 1;
    },
  },
});

export const {
  setContentHandlers,
  setSelectedHandler,
  clearSelectedHandler,
  setRows,
  setTasks,
  setSubtasks,
  setServices,
  removeServiceById,
  removeTaskById,
  removeSubtaskById,
} = archiveSlice.actions;

export default archiveSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    open: false,
    message: "",
    severity: "",
    snackbars: [],
  },
  reducers: {
    handleSnackbarClose: (state, action) => {
      if (action.payload.reason === "clickaway") {
        state.open = false;
        return state;
      }
      state.open = false;
    },
    setSnackbar: (state, action) => {
      state.open = action.payload.open ? action.payload.open : state.open;
      state.message = action.payload.message
        ? action.payload.message
        : state.message;
      state.severity = action.payload.severity
        ? action.payload.severity
        : state.severity;
      state.duration =
        action.payload.severity === "success"
          ? 2500 // 2 seconds;
          : 6000; // 6 seconds
    },
    setError: (state, action) => {
      try {
        state.open = true;
        state.message = action.payload.response?.data?.data?.error;
        state.severity = "error";
      } catch (error) {
        state.open = true;
        state.message = "Something went wrong...";
        state.severity = "error";
        console.error(error);
        console.error(action.payload);
      }
    },
    setErrorV2: (state, action) => {
      try {
        const errors = action.payload.response?.data?.data?.error;
        console.log("🚀 ~ errors:", errors);

        if (errors && Array.isArray(errors)) {
          errors.forEach((error) => {
            const message = error.message?.join(", ") || "An error occurred";
            const details = error.details?.join(", ") || "";
            state.snackbars.push({
              id: new Date().getTime() + Math.random(),
              message: `${message} (${details})`,
              severity: "error",
              open: true,
            });
          });
        } else {
          state.snackbars.push({
            id: new Date().getTime() + Math.random(),
            message: "An unknown error occurred.",
            severity: "error",
            open: true,
          });
        }
      } catch (error) {
        state.snackbars.push({
          id: new Date().getTime() + Math.random(),
          message: "Something went wrong while processing the error.",
          severity: "error",
          open: true,
        });
        console.error(error);
        console.error(action.payload);
      }
    },

    removeSnackbar: (state, action) => {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.id !== action.payload
      );
    },
  },
});


export const {
  handleSnackbarClose,
  setSnackbar,
  setError,
  setErrorV2,
  removeSnackbar,
} = snackbarSlice.actions;

export default snackbarSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    isCompanyLoading: true,
    isTaskboardLoading: true,
    isCardLoading: true,
    isCardInfoLoading: true,
    isServicePageLoading: true,
    isTaskPageLoading: true,
    isSubtaskPageLoading: true,
    isActivitiesLoading: true,
  },
  reducers: {
    // Loading state for company info
    onCompanyLoading: (state) => {
      state.isCompanyLoading = true;
    },
    offCompanyLoading: (state) => {
      state.isCompanyLoading = false;
    },
    // Loading state for taskboard
    onTaskboardLoading: (state) => {
      state.isTaskboardLoading = true;
    },
    offTaskboardLoading: (state) => {
      state.isTaskboardLoading = false;
    },
    // Loading state for services
    onCardLoading: (state) => {
      state.isCardLoading = true;
    },
    offCardLoading: (state) => {
      state.isCardLoading = false;
    },
    // Loading state for card info
    onCardInfoLoading: (state) => {
      state.isCardInfoLoading = true;
    },
    offCardInfoLoading: (state) => {
      state.isCardInfoLoading = false;
    },
    onServicePageLoading: (state) => {
      state.isServicePageLoading = true;
    },
    offServicePageLoading: (state) => {
      state.isServicePageLoading = false;
    },
    onTaskPageLoading: (state) => {
      state.isTaskPageLoading = true;
    },
    offTaskPageLoading: (state) => {
      state.isTaskPageLoading = false;
    },
    onSubtaskPageLoading: (state) => {
      state.isSubtaskPageLoading = true;
    },
    offSubtaskPageLoading: (state) => {
      state.isSubtaskPageLoading = false;
    },
    onActivitiesLoading: (state) => {
      state.isActivitiesLoading = true;
    },
    offActivitiesLoading: (state) => {
      state.isActivitiesLoading = false;
    },
  },
});

export const {
  onCompanyLoading,
  offCompanyLoading,
  onTaskboardLoading,
  offTaskboardLoading,
  onCardLoading,
  offCardLoading,
  onCardInfoLoading,
  offCardInfoLoading,
  onServicePageLoading,
  offServicePageLoading,
  onTaskPageLoading,
  offTaskPageLoading,
  onSubtaskPageLoading,
  offSubtaskPageLoading,
  onActivitiesLoading,
  offActivitiesLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer;

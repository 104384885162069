import { axiosInstance, addQueryParams } from "./common.jsx";

export const listNotifications = async (
  queryParams = {},
  newEndpoint = null
) => {
  let endpoint = newEndpoint || "notifications/";

  if (!newEndpoint) {
    endpoint = addQueryParams(endpoint, queryParams);
  }

  return axiosInstance
    .get(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateNotification = async (notification_id, data) => {
  // Send request to mark target notification as read or unread. Changed the value here instead of the component.
  const endpoint = `notifications/${notification_id}/`;
  return axiosInstance
    .patch(endpoint, data)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const markAllNotificationsAsRead = async () => {
  const endpoint = "notifications/mark-all-as-read/";
  return axiosInstance
    .post(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const listLatestNotifications = async () => {
  const endpoint = "notifications/latest/";
  return axiosInstance
    .get(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationsRead: {
      data: [],
      pagination: { next: null, previous: null, count: 0 },
    },
    notificationsUnread: {
      data: [],
      pagination: { next: null, previous: null, count: 0 },
    },
    notificationsUnreadCount: 0,
    latestNotifications: [],
  },
  reducers: {
    setNotificationsRead: (state, action) => {
      const { data, pagination } = action.payload;
      state.notificationsRead = { data, pagination };
    },
    clearNotificationsRead: (state) => {
      state.notificationsRead = {
        data: [],
        pagination: { next: null, previous: null, count: 0 },
      };
    },
    setNotificationsUnread: (state, action) => {
      const { data, pagination } = action.payload;
      state.notificationsUnread = { data, pagination };
    },
    clearNotificationsUnread: (state) => {
      state.notificationsUnread = {
        data: [],
        pagination: { next: null, previous: null, count: 0 },
      };
    },
    setNotificationsUnreadCount: (state, action) => {
      state.notificationsUnreadCount = action.payload;
    },
    clearNotificationsUnreadCount: (state) => {
      state.notificationsUnreadCount = 0;
    },
    decrementNotificationsUnreadCount: (state) => {
      state.notificationsUnreadCount -= 1;
    },
    setLatestNotifications: (state, action) => {
      state.latestNotifications = action.payload;
    },
    clearLatestNotifications: (state) => {
      state.latestNotifications = [];
    },
    // Switch to read
    switchToRead: (state, action) => {
      const id = action.payload;
      // Find the notification in the unread ones
      const notification = state.notificationsUnread.data.find(
        (item) => item.id === id
      );
      if (notification) {
        // Remove the notification from the unread ones
        state.notificationsUnread.data = state.notificationsUnread.data.filter(
          (item) => item.id !== id
        );
        state.notificationsUnread.pagination.count -= 1;
        state.notificationsUnreadCount -= 1;

        // Add the notification to the read ones
        state.notificationsRead.data.unshift(notification);
        state.notificationsRead.pagination.count += 1;

        // Remove the notification from the latest ones
        state.latestNotifications = state.latestNotifications.filter(
          (item) => item.id !== id
        );
      }
    },
    // Switch to read
    switchToReadLatest: (state, action) => {
      const id = action.payload;
      // Find the notification in the unread ones
      const notification = state.latestNotifications.find(
        (item) => item.id === id
      );
      if (notification) {
        // Remove the notification from the unread ones
        state.notificationsUnread.data = state.notificationsUnread.data.filter(
          (item) => item.id !== id
        );
        state.latestNotifications = state.latestNotifications.filter(
          (item) => item.id !== id
        );
        state.notificationsUnreadCount -= 1;
        state.notificationsUnread.pagination.count -= 1;

        // Add the notification to the read ones
        state.notificationsRead.data.unshift(notification);
        state.notificationsRead.pagination.count += 1;
      }
    },

    // Switch to unread
    switchToUnread: (state, action) => {
      const id = action.payload;
      // Find the notification in the read ones
      const notification = state.notificationsRead.data.find(
        (item) => item.id === id
      );
      if (notification) {
        // Remove the notification from the read ones
        state.notificationsRead.data = state.notificationsRead.data.filter(
          (item) => item.id !== id
        );
        state.notificationsRead.pagination.count -= 1;
        state.notificationsUnreadCount += 1;

        // Add the notification to the unread ones
        state.notificationsUnread.data.unshift(notification);
        state.notificationsUnread.pagination.count += 1;

        // Add the notification to the latest ones
        // if latest notifications is not full, max 5
        if (state.latestNotifications.length < 5) {
          state.latestNotifications.unshift(notification);
        } else {
          // if latest notifications is full, remove the oldest one
          state.latestNotifications.pop();
          state.latestNotifications.unshift(notification);
        }
      }
    },
  },
});

export const {
  setNotificationsRead,
  clearNotificationsRead,
  setNotificationsUnread,
  clearNotificationsUnread,
  setNotificationsUnreadCount,
  clearNotificationsUnreadCount,
  decrementNotificationsUnreadCount,
  setLatestNotifications,
  clearLatestNotifications,
  switchToReadLatest,
  switchToRead,
  switchToUnread,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

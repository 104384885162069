import { createSlice } from "@reduxjs/toolkit";

export const credentialsSlice = createSlice({
  name: "credentials",
  initialState: {
    managerToken: null,
    credentials: [],
    isAuth: false,
  },
  reducers: {
    setCredentialsResult: (state, action) => {
      state.credentials = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previous;
      state.count = action.payload.pagination.count;
    },
    setManagerToken: (state, action) => {
      state.managerToken = action.payload;
    },
    clearManagerToken: (state) => {
      state.managerToken = null;
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    clearCredentials: (state) => {
      state.credentials = [];
    },
    setIsAuth: (state) => {
      state.isAuth = true;
    },
    clearIsAuth: (state) => {
      state.isAuth = false;
    },
  },
});

export const {
  setCredentialsResult,
  setManagerToken,
  clearManagerToken,
  setCredentials,
  clearCredentials,
  setIsAuth,
  clearIsAuth,
} = credentialsSlice.actions;

export default credentialsSlice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "redux/slices/snackbarSlice";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const SnackbarContainer = () => {
  const dispatch = useDispatch();
  const snackbars = useSelector((state) => state.snackbar.snackbars) || [];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    snackbars.forEach((snackbar) => {
      const key = enqueueSnackbar(snackbar.message, {
        variant: snackbar.severity,
        autoHideDuration: snackbar.duration || 6000,
        preventDuplicate: true,
        action: (key) => (
          <IconButton
            onClick={() => {
              closeSnackbar(key);
              dispatch(removeSnackbar(snackbar.id));
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "#fff" }}/>
          </IconButton>
        ),
      });
    });
  }, [snackbars, enqueueSnackbar, closeSnackbar, dispatch]);

  return <></>;
};

export default SnackbarContainer;

import React, { createContext, useContext, useEffect, useState } from "react";

// API
import { listLatestNotifications } from "../api/notifications.jsx";

// Redux
import { useDispatch } from "react-redux";
import {
  setNotificationsUnreadCount,
  setLatestNotifications,
} from "../redux/slices/notificationsSlice";
import { setError } from "../redux/slices/snackbarSlice";

/**
 * NotificationsProvider fetches notifications from the API every 1 minute and
 * provides them to its children. It also handles loading state and errors.
 */
const NotificationsContext = createContext();

export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchNotifications = async () => {
    try {
      const res = await listLatestNotifications();
      const notifications = res.data.notifications;
      setNotifications(notifications);
      dispatch(setLatestNotifications(notifications));
      dispatch(setNotificationsUnreadCount(res.data.count));
      setLoading(false);
    } catch (error) {
      dispatch(setError(error));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access")) {
      fetchNotifications();
      const intervalId = setInterval(fetchNotifications, 60000);
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <NotificationsContext.Provider
      value={{ notifications, fetchNotifications, loading }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import archiveSlice from "./slices/archiveSlice.jsx";
import commentsSlice from "./slices/commentsSlice.jsx";
import companiesSlice from "./slices/companiesSlice.jsx";
import credentialsReducer from "./slices/credentialsSlice.jsx";
import filtersManager from "./slices/filtersManagerSlice.jsx";
import gallerySlice from "./slices/gallerySlice.jsx";
import labelsSlice from "./slices/labelsSlice.jsx";
import masterplanSlice from "./slices/masterplanSlice.jsx";
import miscSlice from "./slices/miscSlice.jsx";
import mySubtasksSlice from "./slices/mySubtasksSlice.jsx";
import notificationsSlice from "./slices/notificationsSlice.jsx";
import notesSlice from "./slices/notesSlice.jsx";
import operatorsSlice from "./slices/operatorsSlice.jsx";
import profileSlice from "./slices/profileSlice.jsx";
import rolesSlice from "./slices/rolesSlice.jsx";
import servicepageSlice from "./slices/servicepageSlice.jsx";
import servicesSlice from "./slices/servicesSlice.jsx";
import snackbarSlice from "./slices/snackbarSlice.jsx";
import statsSlice from "./slices/statsSlice.jsx";
import chronomasterSlice from "./slices/chronomasterSlice.jsx";
import subtaskPageSlice from "./slices/subtaskpageSlice.jsx";
import subtasksTableSlice from "./slices/subtasksTableSlice.jsx";
import taskboardSlice from "./slices/taskboardSlice.jsx";
import taskpageSlice from "./slices/taskpageSlice.jsx";
import teamsSlice from "./slices/teamsSlice.jsx";
import teamSubtasksSlice from "./slices/teamSubtasksSlice.jsx";
import uiSlice from "./slices/uiSlice.jsx";
import userAuthReducer from "./slices/userAuth";
import loadingSlice from "./slices/loadingSlice.jsx";

const appReducer = combineReducers({
  archive: archiveSlice,
  comments: commentsSlice,
  companies: companiesSlice,
  credentials: credentialsReducer,
  filtersManager: filtersManager,
  gallery: gallerySlice,
  labels: labelsSlice,
  loading: loadingSlice,
  masterplan: masterplanSlice,
  misc: miscSlice,
  mySubtasks: mySubtasksSlice,
  notifications: notificationsSlice,
  notes: notesSlice,
  operators: operatorsSlice,
  profile: profileSlice,
  roles: rolesSlice,
  servicepage: servicepageSlice,
  services: servicesSlice,
  snackbar: snackbarSlice,
  stats: statsSlice,
  chronomaster: chronomasterSlice,
  subtaskpage: subtaskPageSlice,
  subtasksTable: subtasksTableSlice,
  taskboard: taskboardSlice,
  taskpage: taskpageSlice,
  teams: teamsSlice,
  teamSubtasks: teamSubtasksSlice,
  ui: uiSlice,
  userAuth: userAuthReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    const { filters } = state;
    state = { filters };
  }
  return appReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

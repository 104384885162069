import { createSlice } from "@reduxjs/toolkit";

export const filtersManager = createSlice({
  name: "filtersManager",
  initialState: {
    filters: {},
    modalFilters: {},
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    clearFilters(state) {
      state.filters = {};
    },
    setModalFilters(state, action) {
      state.modalFilters = action.payload;
    },
    clearModalFilters(state) {
      state.modalFilters = {};
    },
  },
});

export const { setFilters, clearFilters, setModalFilters, clearModalFilters } =
  filtersManager.actions;

export default filtersManager.reducer;

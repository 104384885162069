import { createSlice } from "@reduxjs/toolkit";

export const mySubtaskSlice = createSlice({
  name: "mySubtask",
  initialState: {
    // personal tasks
    tasks: [],
    nextTasks: null,
    previousTasks: null,
    countTasks: null,

    // personal subtasks
    subtasks: [],
    nextSubtasks: null,
    previousSubtasks: null,
    countSubtasks: null,
  },
  reducers: {
    // personal tasks
    setTasksResult: (state, action) => {
      state.tasks = action.payload.data;
      state.nextTasks = action.payload.pagination.next;
      state.previousTasks = action.payload.pagination.previous;
      state.countTasks = action.payload.pagination.count;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    clearTasks: (state) => {
      state.tasks = [];
    },
    // personal subtasks
    setSubtasksResult: (state, action) => {
      state.subtasks = action.payload.data;
      state.nextSubtasks = action.payload.pagination.next;
      state.previousSubtasks = action.payload.pagination.previous;
      state.countSubtasks = action.payload.pagination.count;
    },
    setSubtasks: (state, action) => {
      state.subtasks = action.payload;
    },
    clearSubtasks: (state) => {
      state.subtasks = [];
    },
    clearAll: (state) => {
      state.tasks = [];
      state.nextTasks = null;
      state.previousTasks = null;
      state.countTasks = null;

      state.subtasks = [];
      state.nextSubtasks = null;
      state.previousSubtasks = null;
      state.countSubtasks = null;
    },
  },
});

export const {
  setTasksResult,
  setTasks,
  clearTasks,
  setSubtasksResult,
  setSubtasks,
  clearSubtasks,
  clearAll,
} = mySubtaskSlice.actions;

export default mySubtaskSlice.reducer;

import React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { handleSnackbarClose } from "../redux/slices/snackbarSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NotificationSnackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);

  // Check if the message is this format {'key': 'value'}
  const regex = /\{'([^']*)':\s*'([^']*)'\}/;
  const match = snackbar.message?.match(regex);

  // If it is, extract the correct part
  const message = match ? match[2] : snackbar.message;

  if (!message) return null;

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.duration}
      onClose={(event, reason) => {
        dispatch(handleSnackbarClose({ reason }));
      }}
    >
      <div>
        <Alert
          onClose={(event, reason) => {
            dispatch(handleSnackbarClose({ reason }));
          }}
          severity={snackbar.severity}
          sx={{ width: "100%", whiteSpace: "pre-wrap" }}
        >
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}

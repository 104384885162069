import { createSlice } from "@reduxjs/toolkit";

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    liteSelectedCompany: null,
    selectedCompany: null,
    assignedOperators: null,
    nextPage: null,
    prevPage: null,
    count: null,
    sidebar: null,
  },
  reducers: {
    setCompaniesResult: (state, action) => {
      state.companies = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previous;
      state.count = action.payload.pagination.count;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
    },
    clearCompanies: (state) => {
      state.companies = [];
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
      state.liteSelectedCompany = {
        id: action.payload.id,
        name: action.payload.name,
      };
    },
    setLiteSelectedCompany: (state, action) => {
      state.liteSelectedCompany = action.payload;
    },
    setAssignedOperators: (state, action) => {
      state.assignedOperators = action.payload;
    },
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    },
    clearLiteSelectedCompany: (state) => {
      state.liteSelectedCompany = null;
    },
    clearAssignedOperators: (state) => {
      state.assignedOperators = [];
    },
    setNextPage: (state, action) => {
      state.next = action.payload;
    },
    setPrevPage: (state, action) => {
      state.previous = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    clearSidebar: (state) => {
      state.sidebar = null;
    },
    setLinks: (state, action) => {
      state.sidebar.socialLinks = action.payload;
    },
    clearLinks: (state) => {
      state.sidebar.socialLinks = [];
    },
    addLink: (state, action) => {
      state.sidebar.socialLinks.push(action.payload);
    },
    updateLink: (state, action) => {
      state.sidebar.socialLinks = state.sidebar.socialLinks.map((link) =>
        link.id === action.payload.id ? action.payload : link
      );
    },
    removeLink: (state, action) => {
      state.sidebar.socialLinks = state.sidebar.socialLinks.filter(
        (link) => link.id !== action.payload
      );
    },
  },
});

export const {
  setCompaniesResult,
  setCompanies,
  clearCompanies,
  setSelectedCompany,
  setLiteSelectedCompany,
  clearSelectedCompany,
  clearLiteSelectedCompany,
  setAssignedOperators,
  clearAssignedOperators,
  setNextPage,
  setPrevPage,
  setCount,
  setSidebar,
  clearSidebar,
  setLinks,
  clearLinks,
  addLink,
  updateLink,
  removeLink,
} = companiesSlice.actions;

export default companiesSlice.reducer;
